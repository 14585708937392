<template>
  <div class="tooltip">Wir haben einen <a href="https://seu2.cleverreach.com/f/392905-392831/">Newsletter</a> mit allen Konzertankündigungen!</div>
  <div>
    <h1>Konzerte: Best of Pop(pe) &amp; Beethoven 9</h1>
    <p>Danke an alle, die bei unseren letzten Konzerten waren!</p>
    <img class="fullwidth" src="https://chantiervocal-public.s3.amazonaws.com/Flyer Best of Pop(pe).jpeg" alt="Plakat Best of Pop(pe)" />
    <img class="fullwidth" src="https://chantiervocal-public.s3.amazonaws.com/Flyer Beethoven 2024-11-30.png" alt="Plakat Beethoven" />
    <img class="fullwidth" src="https://chantiervocal-public.s3.amazonaws.com/Infos Beethoven 2024-11-30.jpeg" alt="Plakat Beethoven" />
  </div>

</template>

<script>
export default {
  name: 'FrontPage',
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tooltip {
  color: inherit;
  font-size: 18px;
  text-align: center;
  margin-left: 0.5em;
  margin-bottom: 1em;
}
.tooltip {
  /* tail dimension */
  --b: 2em; /* base */
  --h: 1.5em; /* height */

  --p: 22%; /* main position (0%:left 100%:right) */
  --x: -1.8em; /* tail position (relative to the main position). Can be percentage */
  --r: 0.8em; /* the radius */
  --c: rgb(209, 180, 232); /*#c80700;*/

  padding: 1em;
  border-radius: var(--r) var(--r) min(var(--r),100% - var(--p) - var(--b)/2) min(var(--r),var(--p) - var(--b)/2)/var(--r);
  clip-path: polygon(0 100%,0 0,100% 0,100% 100%,
    clamp(var(--b),var(--p) + var(--b)/2,100%) 100%,
    calc(var(--p) + var(--x)) calc(100% + var(--h)),
    clamp(0%,var(--p) - var(--b)/2,100% - var(--b)) 100%);
  background: var(--c);
  border-image: conic-gradient(var(--c) 0 0) 0 0 1 0/0 0 var(--h) 0/0 999px var(--h) 999px;
}
.tooltip a {
  color: inherit;
}

@media only screen and (min-width: 800px) {
  .tooltip {
    transform: rotate(10deg);
    float: right;
    max-width: 28ch;
  }
}
</style>
